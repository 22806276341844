<template>
  <div class="text-center loading-container">
    <a-icon class="text-primary loading-icon" type="loading"></a-icon>
  </div>
</template>

<script>
export default {
  props: [],
  methods: {
  },
}
</script>

<style lang="scss">
.loading-container {
  background-color: #fff;
  padding: 200px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  .loading-icon {
    font-size: 50px;
  }
}
</style>
