<template>
  <div class="informers-content">
    <div class="sticky-top" style="top: 93px;">
      <a-row class="d-flex align-items-center" :class="{ 'mb-4': !isViewscreen }"
        style="margin: -30px; padding: 8px 30px 22px 30px; background: #fff">
        <a-col :span="10">
          <a-breadcrumb v-if="isViewscreen && !loading" class="mb-2">
            <a-breadcrumb-item><a style="color: #1890FF" @click="redirectToMain">Main</a></a-breadcrumb-item>
            <a-breadcrumb-item><a style="color: rgba(0, 0, 0, 0.45)" href="javascript:;">{{viewscreenName}}</a></a-breadcrumb-item>
            <a-breadcrumb-item><a style="color: rgba(0, 0, 0, 0.45)">Informers</a></a-breadcrumb-item>
          </a-breadcrumb>
          <h3 class="mb-0" style="font-size: 20px">Informers</h3>
        </a-col>
        <a-col :span="14" class="text-right">
          <a-button v-if="!isViewscreen" type="primary" size="large" style="font-size: 16px; font-weight: 300; border-radius: 2px"
            @click="createInformer" :disabled="!permissionCreate">
            <a-icon type="plus" /> Create new
          </a-button>
          <a-button
            v-else
            type="primary"
            size="large"
            style="font-size: 16px; font-weight: 300; border-radius: 2px"
            @click="addInformersToViewscreen"
            :disabled="!informerToViewscreenArray.length"
            :loading="loading"
          >
            Save
          </a-button>
        </a-col>
      </a-row>
      <a-row v-if="isViewscreen" class="d-flex align-items-center mb-4" style="margin: 30px -30px; padding: 13px 24px; background: #FAFAFA">
        <a-col :span="24">
          <div class="d-flex justify-content-end">
            <span v-if="informerToViewscreenArray.length" class="mr-4 text-gray-7">{{ imageCounter }}</span>
            <a-checkbox :checked="isAddAllChecked" @change="handleAddAllInformers">Select All</a-checkbox>
          </div>
        </a-col>
      </a-row>
    </div>
    <div style="padding: 24px; background-color: #fff">
      <div class="d-flex align-items-center mb-4">
        <AppSelect v-model="filters.applicationId" :apps="apps" class="mr-3" @change="listUpdate"
          :disabled="appsLoading" style="width: 220px;" />
        <LocalizationsSelect v-model="filters.localizationId" :localizations="languagesOptions"
          :disabled="languagesLoading" class="mr-4" @change="listUpdate" />
        <ActiveStatus v-model="filters.active" @change="listUpdate" :disabled="informersLoading" />
        <a-input-search style="width: 200px; height: 40px; margin-top: auto;" size="large" v-model="filters.name"
          placeholder="Input search text" enter-button @search="listUpdate" :disabled="informersLoading"
          class="ml-auto" />
      </div>
      <div v-if="!informersLoading">
        <draggable v-if="informers.length" v-model="informers" v-bind="dragOptions" @start="drag = true"
        @end="sortInformers()">
        <transition-group>
          <InformerCard v-for="(informer, index) in informers" :class="{ 'mt-4': index }" :informer-info="informer"
            :permissionEdit="permissionEdit" :key="informer.id"
            @childOpenFunction="editInformer(informer.id)"
            :isInformerChecked="informerToViewscreenArray.includes(informer.id)"
            :isViewscreen="isViewscreen"
            @addToViewscreenArray="handleAddToViewscreenArr(informer.id)"
            @deleteFromViewscreenArray="handleDeleteViewscreeArr(informer.id)" />
        </transition-group>
        </draggable>
        <EmptyState v-else :text="emptyStateText" :isAddDisabled="!permissionCreate" textBtn="Add" @add="createInformer" />
      </div>
      <LoadingState v-else/>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppSelect from '@/components/custom/filters/appSelect.vue'
import LocalizationsSelect from '@/components/custom/filters/localizationsSelect.vue'
import ActiveStatus from '@/components/custom/filters/activeStatus.vue'
import InformerCard from '@/views/apps/informers/informersList/informerCard.vue'
import EmptyState from '@/components/custom/emptyState.vue'
import LoadingState from '@/components/custom/loadingState.vue'
import informerService from '@/services/api/apiInformersService'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import ViewscreenService from '@/services/api/apiViewscreenService'
import draggable from 'vuedraggable'
export default {
  name: 'Informers',
  mixins: [accessMix],
  components: {
    draggable,
    AppSelect,
    LocalizationsSelect,
    ActiveStatus,
    InformerCard,
    EmptyState,
    LoadingState,
  },
  data() {
    return {
      informersLoading: true,
      appsLoading: false,
      languagesLoading: false,
      informers: [],
      languages: [],
      displays: [
        { label: 'Live', value: 1 },
        { label: 'Promo', value: 3 },
        { label: 'Main', value: 4 },
      ],
      drag: false,
      apps: undefined,
      filters: {
        applicationId: undefined,
        localizationId: undefined,
        active: true,
        name: undefined,
      },
      pagination: {
        current: 1,
        results: 15,
        total: 0,
      },
      loading: false,
      informerToViewscreenArray: [],
      viewscreenData: null,
      isAddAllChecked: false,
    }
  },
  computed: {
    ...mapState(['user']),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    languagesOptions() {
      return this.languages.map(function (row) {
        return { value: row.id, label: row.desc }
      })
    },
    permissionCreate() {
      return this.checkPermissions(this.user.permissions, 'informers', 'create')
    },
    permissionEdit() {
      return this.checkPermissions(this.user.permissions, 'informers', 'edit')
    },
    emptyStateText() {
      return this.filters.name?.length ? 'Oops! Nothing matches your search.' : 'Add first informer'
    },
    isViewscreen() {
      return !!this.$route.params.sectionId
    },
    viewscreenName() {
      if (!this.viewscreenData) {
        return null
      }
      return this.viewscreenData.name
    },
    imageCounter () {
      return `${this.informerToViewscreenArray.length} ${this.informerToViewscreenArray.length === 1 ? 'image' : 'images'} selected`
    },
  },
  async created() {
    await this.fetchApplications()
    await this.fetchLocalizations()
    await this.listUpdate()
    if (this.$route.params.viewscreenId) {
      await this.getViewscreenTab(this.$route.params.viewscreenId, '0')
    }
  },
  watch: {
    isActive(value) {
      this.isActiveSectionStatus = value
    },
    informers() {
      this.informerToViewscreenArray = []
      this.isAddAllChecked = false
    },
  },
  methods: {
    handleAddToViewscreenArr(id) {
      this.informerToViewscreenArray.push(id)
      if (this.informerToViewscreenArray.length === this.informers.length) {
        this.isAddAllChecked = true
      }
    },
    handleDeleteViewscreeArr(id) {
      const deletedIndex = this.informerToViewscreenArray.findIndex(item => item === id)
      if (deletedIndex !== -1) {
        this.informerToViewscreenArray.splice(deletedIndex, 1)
      }
      this.isAddAllChecked = false
    },
    handleAddAllInformers() {
      if (!this.isAddAllChecked) {
        const nonDuplicateIds = this.informers.map(informer => informer.id).filter(id => !this.informerToViewscreenArray.includes(id))
        this.informerToViewscreenArray.push(...nonDuplicateIds)
        this.isAddAllChecked = true
      } else {
        this.informerToViewscreenArray = []
        this.isAddAllChecked = false
      }
    },
    editInformer(informerId) {
      this.$router.push(`/informers/edit-informer/${informerId}`)
    },
    redirectToMain() {
      this.$router.push(`/main/${this.$route.params.viewscreenId}`)
    },
    async listUpdate() {
      try {
        this.informersLoading = true
        await this.getInformers()
      } catch (e) {
        console.error(e)
      } finally {
        this.informersLoading = false
      }
    },
    async addInformersToViewscreen() {
      try {
        this.loading = true
        const informersPayload = {
          item_ids: this.informerToViewscreenArray,
        }
        const addInformersResponse = await ViewscreenService.addMultipleSectionItems(this.$route.params.sectionId, informersPayload)
        if (addInformersResponse.data.data) {
          this.$notification.success({
            message: 'Informers successfully added to viewscreen',
          })
          this.redirectToMain()
        }
      } catch (err) {
        this.$notification.error({
          message: err.response.data.error.message,
        })
        this.loading = false
      }
    },
    async getInformers() {
      const { name, active, localizationId, applicationId } = this.filters
      const searchParams = new URLSearchParams()
      searchParams.append('is_active', active)

      if (typeof localizationId !== 'undefined') {
        searchParams.append('lang_id', localizationId)
      }

      if (typeof applicationId !== 'undefined') {
        searchParams.append('app_id', applicationId)
      }
      if (typeof name !== 'undefined') {
        searchParams.append('name', name)
      }

      if (this.isViewscreen) {
        searchParams.append('viewscreen_section_id', this.$route.params.sectionId)
      }

      try {
        const informerResponse = await informerService.getInformers(searchParams)
        this.informers = informerResponse.data?.data
      } catch (e) {
        const errorMessage =
          e.response.data?.error?.message ||
          'Something went wrong while getting informers'
        this.$notification.error({
          message: errorMessage,
        })
      }
    },
    createInformer() {
      this.$router.push('/informers/create-informer')
    },
    async fetchApplications() {
      this.appsLoading = true
      try {
        const url = '/admin/applications'
        const response = await apiClient.get(url)
        if (response.data.data) {
          this.apps = response.data.data
          this.apps.forEach(item => {
            if (item.is_default === 1) {
              this.filters.applicationId = item.application_id
            }
          })
        }
      } finally {
        this.appsLoading = false
      }
    },
    async fetchLocalizations() {
      this.languagesLoading = true
      const url = '/admin/localizations'
      try {
        const response = await apiClient.get(url)
        this.languages = response.data.data
      } catch (error) {
        console.log('Error while trying to get locales', error)
      } finally {
        this.languagesLoading = false
      }
    },
    async getViewscreenTab(id, langId) {
      this.loading = true
      try {
        const response = await ViewscreenService.getViewscreenTab(id, langId)
        if (response.data.data) {
          this.viewscreenData = response.data.data
        }
      } finally {
        this.loading = false
      }
    },
    async sortInformers() {
      const informersSort = this.informers.map((informer, index, informerArr) => {
        return {
          informer_id: informer.id,
          sort: index + 1,
        }
      })
      const sort = { sort: informersSort }
      try {
        await informerService.sortInformers(28, sort) // TODO: CHANGE HARCODE APP AFTER BACK FIX
        this.$notification.success({
          message: 'Sucessfully sorted',
        })
      } catch (error) {
        console.log(error)
      }
      this.drag = false
    },
  },
}
</script>
<style>
/*.ant-modal { width: 1000px !important; }*/
.ant-modal-title {
  font-weight: bold !important;
  font-size: 18px !important;
}
.banners-active-filter-switch {
  /*border: 1px solid #000;*/
  display: inline-block !important;
  padding: 0 0.5rem 0 0.5rem;
  line-height: 38px;
  width: auto;
}
.banners-loading-spinner {
  /*width: 64px;*/
  /*height: 64px;*/
  border-radius: 2px;
  padding: 1rem;
  font-size: 96px;
  /*background: rgba(0, 0, 0, 0.1);*/
  margin-top: 6rem;
  position: absolute;
  margin-left: calc(50% - 40px);
  z-index: 999999;
}
.select-filter-localization {
  max-height: 38px !important;
  width: 100%;
}
.filter-drawer .ant-drawer-content-wrapper,
.filter-drawer .ant-drawer-content,
.filter-drawer .ant-drawer-wrapper-body {
  overflow: initial;
}
/* @media screen and (min-width: 1024px) {
      .filter-drawer .ant-drawer-wrapper-body {
        overflow-y: hidden;
      }
    } */
</style>
<style scoped>
.banner-slider::before {
  display: none;
}
.banner-slider {
  display: grid;
  grid-template-columns: repeat(auto-fit, 343px);
  grid-gap: 32px 57px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 24px 0;
  background: #fff;
  border-radius: 2px;
}
.banner-modal {
  width: 1000px !important;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 343px);
  grid-gap: 32px 57px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 24px 0;
  background: #fff;
  border-radius: 2px;
}
.flip-grid-move {
  transition: transform 0.5s;
  cursor: pointer;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

@media screen and (max-width: 1473px) {
  .banner-slider {
    grid-template-columns: repeat(3, 250px);
  }
  .banner-slider .flex-column {
    width: 100px !important;
  }
}
@media screen and (max-width: 1200px) {
  .banner-slider {
    grid-template-columns: repeat(auto-fit, 250px);
  }
}
</style>
