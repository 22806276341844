import apiClient from '@/services/axios'

class InformerService {
  async getInformers(searchParams) {
    const url = `/admin/informers?${searchParams}`
    return apiClient.get(url)
  }

  async getInformer(informerId) {
    const url = `/admin/informers/${informerId}`
    return apiClient.get(url)
  }

  async createInformer(data) {
    const url = '/admin/informers'
    return apiClient.post(url, data)
  }

  async updateInformer(informerId, data) {
    const url = `/admin/informers/${informerId}`
    return apiClient.put(url, data)
  }

  async deleteInformer(informerId) {
    const url = `/admin/informers/${informerId}`
    return apiClient.delete(url)
  }

  async uploadInformerImage(file) {
    const formData = new FormData()
    formData.append('cover', file.originFileObj)

    const url = '/admin/informers/cover'
    return apiClient.post(url, formData)
  }

  async sortInformers(appId, data) {
    const url = `/admin/informers/sort/${appId}`
    return apiClient.post(url, data)
  }
}
export default new InformerService()
