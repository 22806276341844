<template>
  <div class="informer-card" :class="{ 'tech-informer': isTechInformer }">
    <div>
      <div>
        <div class="d-flex flex-column informer-card__image-container mr-3">
          <img v-if="informerHasImage" @click="handleEditInformer" class="informer-card__img" :src="selectedInfo.path"
            alt="" />
          <div>
            <a-icon v-if="isTechInformer" type="warning" class="mr-2 informer-card__warning-icon"/>
            <span class="informer-card__name">{{ selectedInfo.title.length > 40 ? selectedInfo.title.slice(0, 40) + '...' : selectedInfo.title  }}</span>
          </div>
          <span style="opacity: 45%;" class="informer-card__description">{{ selectedInfo.description?.length > 109 ? selectedInfo.description.slice(0, 109) + '...' : selectedInfo.description }}</span>
        </div>
        <a-tabs :default-active-key="languages[0].name" :active-key="selectedLanguage" @change="handleTabChange"
          :tabBarStyle="{ border: 'none' }">
          <a-tab-pane v-for="language in languages" :key="language.desc" :tab="language.name" />
        </a-tabs>
      </div>
    </div>
    <div @click="handleEditInformer" class="flex-grow-1 informer-card__info" :style="{ top: informerHasImage ? '-25px' : '-10px' }">
      <div class="mb-3 d-flex align-items-center">
        <div class="util-width">
          <span class="informer-card__title">Tech name</span>
          <div>{{ informerInfo.name }}</div>
        </div>
        <div class="util-width" :style="{ width: informerHasImage ? '' : '35%' }">
          <div v-if="informerHasImage">
            <span class="informer-card__title">Activity</span>
            <div>
              {{ formatDate(this.dateStart) }} -
              <span v-if="this.dateEnd">{{ formatDate(this.dateEnd) }}</span>
              <span v-else style="font-size: 20px;">&infin;</span>
            </div>
          </div>
          <div v-else>
            <span class="informer-card__title">Apps</span>
              <div style="line-height: 2.1rem">
                <a-tag style="margin-right: 15px; padding: 1px 8px; border-radius: 2px"
                  v-for="app in informerInfo.apps.slice(0, 2)" :key="app.id">
                  {{ app.name }}
                  <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
                  <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
                  <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
                </a-tag>
                <a-tag v-if="informerInfo.apps.length > 3">
                  ...
                </a-tag>
              </div>
          </div>
        </div>
        <div v-if="uuidIsPresent && !informerHasImage">
          <span class="informer-card__title">UUID</span>
          <div>{{ informerInfo.uuid }}</div>
        </div>
        <div class="ml-auto">
          <a-checkbox v-if="isViewscreen" class="custom-checkbox" :checked="addToViewscreen" @change="handleViewscreenChange"/>
          <a-switch v-else class="ml-auto" :default-checked="this.switchActive" @change="changeActive"
            :disabled="!permissionEdit" />
        </div>
      </div>
      <div class="mt-3 d-flex align-items-center">
        <div v-if="informerHasImage" class="util-width">
          <span class="informer-card__title">Tag</span>
          <div>{{ informerInfo.tag !== 'no_tag' ? informerInfo.tag.charAt(0).toUpperCase() + informerInfo.tag.slice(1) :
            'No tag' }}
          </div>
        </div>
        <div v-if="uuidIsPresent && informerHasImage">
          <span class="informer-card__title">UUID</span>
          <div>{{ informerInfo.uuid }}</div>
        </div>
      </div>
      <div class="mt-3 d-flex align-items-center">
        <div class="util-width">
          <div v-if="informerHasImage">
            <span class="informer-card__title">Apps</span>
              <div style="line-height: 2.1rem">
                <a-tag style="margin-right: 15px; padding: 1px 8px; border-radius: 2px"
                  v-for="app in informerInfo.apps.slice(0, 2)" :key="app.id">
                  {{ app.name }}
                  <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
                  <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
                  <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
                </a-tag>
                <a-tag v-if="informerInfo.apps.length > 3">
                  ...
                </a-tag>
              </div>
          </div>
          <div v-else>
            <span class="informer-card__title">Tag</span>
            <div>{{ informerInfo.tag !== 'no_tag' ? informerInfo.tag.charAt(0).toUpperCase() + informerInfo.tag.slice(1) : 'No tag' }}</div>
          </div>
        </div>
        <div v-if="!informerHasImage">
          <span class="informer-card__title">Activity</span>
          <div>
            {{ formatDate(this.dateStart) }} -
            <span v-if="this.dateEnd">{{ formatDate(this.dateEnd) }}</span>
            <span v-else style="font-size: 20px;">&infin;</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import informerService from '@/services/api/apiInformersService'

export default {
  name: 'informerCard',
  props: ['informerInfo', 'permissionEdit', 'isInformerChecked', 'isViewscreen'],
  computed: {
    ...mapState(['user']),
    switchActive() {
      return this.informerInfo.is_active
    },
    dateStart() {
      return this.informerInfo.start_date
    },
    dateEnd() {
      return this.informerInfo.end_date
    },
    selectedInfo() {
      return this.informerInfo.localizations[this.selectedLanguage]
    },
    informerHasImage() {
      return this.selectedInfo.path !== null
    },
    uuidIsPresent() {
      return this.informerInfo.uuid && this.informerInfo.uuid.length > 0
    },
    isTechInformer() {
      return this.informerInfo.tag === 'maintenance'
    },
  },
  mounted() {
  },
  data() {
    return {
      languages: [],
      selectedLanguage: '',
      displays: {
        1: 'Live',
        3: 'Promo',
        4: 'Main',
      },
      addToViewscreen: false,
    }
  },
  watch: {
    isInformerChecked(value) {
      this.addToViewscreen = value
    },
  },
  created() {
    const langKeys = Object.keys(this.informerInfo.localizations)
    this.languages = langKeys.map((lang) => {
      return {
        name: lang.toUpperCase(),
        desc: lang,
      }
    })
    this.selectedLanguage = this.languages[0].desc
    if (this.isInformerChecked) {
      this.addToViewscreen = true
    }
  },
  methods: {
    handleEditInformer(event) {
      if (!event.target.classList.contains('ant-switch') && !this.isViewscreen) {
        this.$emit('childOpenFunction')
      }
    },
    handleTabChange(selectedLangKey) {
      this.selectedLanguage = selectedLangKey
    },
    async changeActive(checked) {
      try {
        const informerPayload = {
          is_active: checked,
        }
        const informerResponse = await informerService.updateInformer(this.informerInfo.id, informerPayload)
        this.$notification.success({
          message: 'Active status changed',
          description: `${this.informerInfo.name} status have been changed`,
        })
        if (informerResponse.data.error) {
          const errorMessage =
            informerResponse.data.error?.message ||
            'Something went wrong while updating informer activity'
          this.$notification.error({
            message: errorMessage,
          })
          this.loading = false
          return
        }
      } catch (e) {
        const errorMessage =
          e.response.data?.error?.message ||
          'Something went wrong while updating activity'
        this.$notification.error({
          message: errorMessage,
        })
      }
    },
    formatDate: function (date) {
      return this.$moment(date).tz('Etc/GMT-3').format('DD.MM.YY HH:mm')
    },
    handleViewscreenChange(event) {
      this.addToViewscreen = event.target.checked
      const action = event.target.checked ? 'addToViewscreenArray' : 'deleteFromViewscreenArray'
      this.$emit(action, this.informerInfo.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.util-width {
  width: 40%;
}

.informer-card {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 16px 24px 0;
  display: flex;
  align-items: center;
  &__img {
    width: 352px;
    height: 128px;
  }

  &__image-container {
    width: 352px;
    gap: 8px;
  }

  &__name {
    font-weight: 600;
    color: #000000D9;
    font-size: 14;
  }

  &__title {
    color: #bfbfbf;
  }

  &__description {
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__info {
    position: relative;
  }
  &__warning-icon {
    font-size: 18px;
    color: #E5A000;
    position: relative;
    top: 1px;
  }
}
.informer-card.tech-informer {
    &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px; /* Adjust thickness */
    background: repeating-linear-gradient(
      -45deg,
      #FFE8A3,
      #FFE8A3 6px,
      #FFFFFF 6px,
      #FFFFFF 12px
    );
  }
}
</style>
